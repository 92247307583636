import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../components/Loading';
import RateConversation from '../../../components/Rating/RateConversation';
import usePrevious from '../../../hooks/usePrevious';
import { useV1Socket } from '../../../hooks/useV1Socket';
import ConversationButtons from '../../../modules/conversation/ConversationButtons';
import KeyboardInput from '../../../modules/conversation/KeyboardInput';
import RoomEndingWarningSnackbar from '../../../modules/conversation/RoomEndingWarningSnackbar';
import Landing from '../../../modules/landing/Landing';
import BoostModal from '../../../modules/scribe/BoostModal';
import EditProfileModal from '../../../modules/scribe/EditProfileModal';
import ScribeKeypressHandlerContainer from '../../../modules/scribe/ScribeKeypressHandlerContainer';
import { ZoomBroadcastCaptionsPopup } from '../../../modules/scribe/ZoomBroadcastCaptionsPopup';
import { selectIsBoosting } from '../../../selectors/boost';
import { selectConversationMuted, selectShowKeyboardInput } from '../../../selectors/combined';
import { selectInTwilioCalls, selectIsInConversation } from '../../../selectors/conversation';
import { selectCurrentConversationEndingStage } from '../../../selectors/conversationEnd';
import { selectStatusBoost, selectStatusRoomId, selectZoomConference } from '../../../selectors/legacy-conversation';
import { selectIsRateConversationOpen } from '../../../selectors/rate-conversation';
import { selectRecallAIStatus } from '../../../selectors/recallAI';
import { selectSelectedId } from '../../../selectors/saved-transcript';
import { selectElectronCaptionMode, selectFullScreen, selectLoading } from '../../../selectors/ui';
import { selectIsUserTemporary } from '../../../selectors/userProfile';
import { ConversationEndingStage } from '../../../store/slices/conversationEnd';
import { useAppSelector } from '../../../store/store';
import ConversationView from '../ConversationView/ConversationView';
import styles from './ConversationPage.styles';
const useStyles = makeStyles(styles);
const ConversationPage = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [askingUserName, setAskingUserName] = useState(false);
    const [avaNameRequested, setAvaNameRequested] = useState(undefined);
    const [conversationContainerHovered, setConversationContainerHovered] = useState(false);
    const [zoomExportPopup, setZoomExportPopup] = useState();
    const navigatingAway = useRef(false);
    const fullScreen = useAppSelector(selectFullScreen);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const loading = useAppSelector(selectLoading);
    const isInConversation = useAppSelector(selectIsInConversation);
    const inTwilioCalls = useAppSelector(selectInTwilioCalls);
    const muted = useAppSelector(selectConversationMuted);
    const isBoosting = useAppSelector(selectIsBoosting);
    const isRateConversationOpen = useAppSelector(selectIsRateConversationOpen);
    const showKeyboardButton = useAppSelector(selectShowKeyboardInput);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const tempUser = useAppSelector(selectIsUserTemporary);
    const conversationEndingStage = useAppSelector(selectCurrentConversationEndingStage);
    const statusRoomId = useAppSelector(selectStatusRoomId);
    const zoomConference = useAppSelector(selectZoomConference);
    const statusBoost = useAppSelector(selectStatusBoost);
    const [ws, websocketStatus] = useV1Socket();
    const navigate = useNavigate();
    useEffect(() => {
        // This is expanded view only so can stay on this component
        if (inTwilioCalls && zoomConference && zoomExportPopup !== statusRoomId && zoomExportPopup !== 'display') {
            setZoomExportPopup('display');
        }
    }, [inTwilioCalls, zoomConference, zoomExportPopup]);
    const handleEditProfilModalClose = ({ updated }) => {
        setAskingUserName(!updated);
    };
    const prevIsInConversation = usePrevious(isInConversation);
    useEffect(() => {
        if (isInConversation) {
            //TODO: Revisit this prompting logic once we have the tech to differentiate QR code joins
            // if (userName === 'web guest' && !muted && recallAIStatus === 'NOT_CONNECTED') {
            //   setAskingUserName(true);
            //   setAvaNameRequested(status?.host?.userName);
            // }
        }
        if (!isInConversation && prevIsInConversation) {
            if (selectSelectedId(window.store.getState())) {
                navigatingAway.current = true;
                navigate('/web/conversations');
            }
        }
    }, [isInConversation]);
    if (loading) {
        return React.createElement(Loading, null, t('joiningConversation'));
    }
    if (navigatingAway.current || conversationEndingStage === ConversationEndingStage.END_ON_BACKEND) {
        return React.createElement(Loading, null);
    }
    return (React.createElement("div", { style: { height: '100%' } },
        React.createElement("audio", { id: "player", controls: true, muted: true, style: { display: 'none' } }),
        !window.isElectron && zoomExportPopup === 'display' && (React.createElement(ZoomBroadcastCaptionsPopup, { on: zoomExportPopup === 'display', setOff: () => {
                setZoomExportPopup(statusRoomId);
            } })),
        React.createElement("div", { className: `conversation-container ${classes.container}`, onMouseEnter: () => setConversationContainerHovered(true), onMouseLeave: () => setConversationContainerHovered(false), style: {
                overflowX: 'hidden',
                position: 'relative',
                // If we are in caption mode, we want Electron to manage the
                // background, since we want it to be transparent.
                backgroundColor: theme.palette.background.default,
                backgroundImage: null,
                height: `100%`,
                fontFamily: theme.typography.fontFamily,
                width: '100%',
            } },
            askingUserName && (React.createElement(EditProfileModal, { open: askingUserName, close: (e) => handleEditProfilModalClose(e), title: t('profilModal.enterYourName'), subtitle: `${t('profilModal.weNeedToAnnouceYouTo')} &${avaNameRequested || ''}`, defaultUserName: "", action: t(`profilModal.continue`) })),
            React.createElement(BoostModal, { fullScreen: fullScreen, open: isBoosting, boostList: statusBoost || [] }),
            showKeyboardButton && (React.createElement(React.Fragment, null,
                React.createElement(KeyboardInput, null))),
            React.createElement("span", null, websocketStatus === 'online' && (!muted || (recallAIStatus === 'CAPTIONING' && !tempUser)) && (
            // eslint-disable-next-line
            React.createElement(ScribeKeypressHandlerContainer, null))),
            React.createElement(RoomEndingWarningSnackbar, { variant: 'expanded' }),
            !loading && !isInConversation && React.createElement(Landing, null),
            isInConversation && React.createElement(ConversationView, { conversationContainerHovered: conversationContainerHovered }),
            React.createElement(ConversationButtons, null)),
        !isInConversation && isRateConversationOpen && React.createElement(RateConversation, null)));
};
export default ConversationPage;
