import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
const useStyles = makeStyles(() => ({
    root: {
        cursor: 'pointer',
        background: 'black',
        color: 'white',
        padding: '5px 10px',
    },
}));
export function ConvoV2FollowTranscriptButton(props) {
    const classes = useStyles();
    return (React.createElement(Box, { className: classNames(classes.root, props.className), onClick: props.onClick }, "Scroll down"));
}
