import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { selectLang, selectSpeakersMap, selectTranscript } from '../../selectors/legacy-conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { getText } from '../../utils/scribeUtils';
import { ConvoV2ParticipantAvatar } from './ConvoV2ParticipantAvatar';
export function ConvoV2TranscriptItem({ transcriptId, animate }) {
    var _a, _b, _c, _d, _e, _f;
    const transcript = useAppSelector((state) => selectTranscript(state, transcriptId));
    const speakers = useAppSelector(selectSpeakersMap);
    const lang = useAppSelector(selectLang);
    const avaId = useAppSelector(selectAvaId);
    const theme = useTheme();
    const [fresh, setFresh] = useState(false);
    const [show, setShow] = useState(false);
    const speaker = speakers[transcript.author];
    const avatarUrl = (speaker === null || speaker === void 0 ? void 0 : speaker.photoUrl) || (speaker === null || speaker === void 0 ? void 0 : speaker.userAvatar);
    const isSpeakerCurrentUser = transcript.author === avaId;
    const speakerBackgroundColor = ((_b = (_a = speaker === null || speaker === void 0 ? void 0 : speaker.theme) === null || _a === void 0 ? void 0 : _a.light_theme) === null || _b === void 0 ? void 0 : _b.background_color) || theme.palette.success.light;
    const speakerTextColor = ((_d = (_c = speaker === null || speaker === void 0 ? void 0 : speaker.theme) === null || _c === void 0 ? void 0 : _c.light_theme) === null || _d === void 0 ? void 0 : _d.text_color) || 'white';
    const speakerColor = ((_f = (_e = speaker === null || speaker === void 0 ? void 0 : speaker.theme) === null || _e === void 0 ? void 0 : _e.light_theme) === null || _f === void 0 ? void 0 : _f.speaker_color) || theme.palette.success.dark;
    const transcriptText = getText(transcript, lang);
    useEffect(() => {
        if (!animate)
            return;
        setFresh(true);
        const timeout = setTimeout(() => {
            setFresh(false);
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, [transcriptText]);
    useEffect(() => {
        setShow(true);
    }, []);
    if (!transcriptText)
        return null;
    return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'stretch',
            width: '100%',
            marginY: 1,
            transform: show ? '' : 'scaleY(0)',
            transition: animate ? 'transform 0.3s ease-in-out' : '',
        } },
        React.createElement(Box, { sx: {
                flexGrow: 0,
                marginX: 2,
                marginTop: 1,
            } },
            React.createElement(ConvoV2ParticipantAvatar, { speaker: speaker, size: 48 })),
        React.createElement(Box, { sx: {
                flexGrow: 1,
                backgroundColor: animate && fresh ? '#fff4da' : 'white',
                transition: animate && fresh ? '' : 'background-color 2s ease',
                borderRadius: 4,
                paddingX: 3,
                paddingTop: 1,
                paddingBottom: 2,
                marginRight: 2,
            } },
            React.createElement(Typography, { sx: {
                    fontWeight: '300',
                    fontSize: 20,
                    color: speakerColor,
                    marginBottom: 1,
                } }, isSpeakerCurrentUser ? 'You' : speaker === null || speaker === void 0 ? void 0 : speaker.userName),
            React.createElement(Typography, { sx: {
                    fontWeight: '200',
                    fontSize: 16,
                    color: speakerTextColor,
                } }, transcriptText))));
}
