import SendIcon from '@mui/icons-material/Send';
import { Tooltip } from '@mui/material';
import Fab from '@mui/material/Fab';
import Input from '@mui/material/Input';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scribeCreateTranscript, scribeDeselectTranscriptWord } from '../../actions/transcript';
import useTextToSpeechManager from '../../hooks/useTextToSpeechManager';
import { selectCurrentSelectedVoice, selectIsUsingTts } from '../../selectors/textToSpeech';
import { selectFontSize, selectKeyboardInputShown } from '../../selectors/ui';
import { selectAvaId, selectUserName } from '../../selectors/userProfile';
import { stopRecording } from '../../store/slices/audioV2';
import { setKaraokeState } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { KARAOKE_PRE_DELAY_MAPPING } from '../../utils/karaoke';
import TtsButton from '../textToSpeech/TtsButton';
const useStyles = makeStyles((theme) => ({
    input: {
        position: 'absolute',
        bottom: 30,
        right: 160,
        width: 'calc(100% - 180px)',
        fontSize: (props) => props.fontSize,
    },
    fab: {
        position: 'absolute',
        bottom: 30,
        color: theme.palette.ava.white,
        zIndex: 101,
        '&:hover': {
            // I couldn't find a way to overwrite the behavior without !important
            // even in style/sx props of the component
            backgroundColor: `${theme.palette.ava.deepBlue} !important`,
        },
    },
}));
const KeyboardInput = () => {
    const fontSize = useAppSelector(selectFontSize);
    const classes = useStyles({ fontSize });
    const theme = useTheme();
    const { t } = useTranslation();
    const avaId = useAppSelector(selectAvaId);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const userName = useAppSelector(selectUserName);
    const currentSelectedVoice = useAppSelector(selectCurrentSelectedVoice);
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('');
    const [isAlreadyTyping, setIsAlreadyTyping] = useState(false);
    const textToSpeechManager = useTextToSpeechManager();
    const sendMessage = (event, karaoke) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(scribeDeselectTranscriptWord());
        const newTranscript = dispatch(scribeCreateTranscript(undefined, value, avaId, 1, false, undefined));
        if (karaoke && newTranscript && currentSelectedVoice) {
            const startDelay = KARAOKE_PRE_DELAY_MAPPING[currentSelectedVoice.voiceProviderId];
            setTimeout(() => {
                dispatch(setKaraokeState({ wordIndex: 0, transcriptId: newTranscript.id }));
            }, startDelay);
            dispatch(stopRecording());
        }
        dispatch(scribeDeselectTranscriptWord());
        setValue('');
    };
    const onInputChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(scribeDeselectTranscriptWord());
        setValue(event.target.value);
    };
    const onKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            // User is pressing Enter without Shift, allow the default action of
            // sending a message.
            event.preventDefault();
            handleSend(event);
        }
    };
    const onSynthesizeSpeech = (event, karaoke) => {
        textToSpeechManager === null || textToSpeechManager === void 0 ? void 0 : textToSpeechManager.handleTextToSpeech({
            text: value,
            voiceGender: currentSelectedVoice === null || currentSelectedVoice === void 0 ? void 0 : currentSelectedVoice.gender,
            voiceId: currentSelectedVoice === null || currentSelectedVoice === void 0 ? void 0 : currentSelectedVoice.id,
        });
        sendMessage(event, karaoke);
    };
    const handleSend = useCallback((e) => {
        if (!isUsingTts) {
            sendMessage(e, false);
        }
        else {
            onSynthesizeSpeech(e, true);
        }
    }, [isUsingTts, value]);
    useEffect(() => {
        if (!userName || !avaId)
            return;
        if (value.length > 0 && !isAlreadyTyping) {
            setIsAlreadyTyping(true);
            textToSpeechManager === null || textToSpeechManager === void 0 ? void 0 : textToSpeechManager.handleSendIAmTyping({ userName, avaId });
        }
        if (value.length === 0 && isAlreadyTyping) {
            setIsAlreadyTyping(false);
        }
    }, [value, isAlreadyTyping]);
    return (React.createElement(React.Fragment, null,
        keyboardInputShown && (React.createElement("form", { onSubmit: sendMessage },
            React.createElement(Slide, { direction: "left", in: true, mountOnEnter: true, unmountOnExit: true },
                React.createElement(Input, { classes: { root: classes.input }, onChange: onInputChange, value: value, "data-qa": "ttsInputField", placeholder: t('conversation.typeTranscriptHere'), onKeyDown: onKeyDown, autoFocus: true, multiline: true, minRows: '1' })))),
        keyboardInputShown && React.createElement(TtsButton, null),
        keyboardInputShown && (React.createElement(Tooltip, { title: !isUsingTts ? t('ccMode.keyboard.tooltip.send') : t('ccMode.keyboard.sendAsVoiceMessage') },
            React.createElement(Fab, { classes: { root: classes.fab }, "data-qa": "sendTTS", onClick: handleSend, style: {
                    right: 20,
                    backgroundColor: theme.palette.ava.blue,
                } },
                React.createElement(SendIcon, null))))));
};
export default KeyboardInput;
