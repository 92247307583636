import { useTheme } from '@mui/material/styles';
import React from 'react';
import { scribeSelectTranscriptWord } from '../../actions';
import { selectParticipantOnWord } from '../../selectors/combined';
import { selectCurrentCaptionsColor } from '../../selectors/transcriptSelectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
const parseWord = (word, classes = '', participantOnWord) => {
    if (word[0] === '`' && word[word.length - 1] === '`') {
        return parseWord(word.slice(1, word.length - 1), `${classes} tr-edited`);
    }
    if (word[0] === '~' && word[word.length - 1] === '~') {
        return parseWord(word.slice(1, word.length - 1), `${classes} tr-disabled`);
    }
    const shownWord = participantOnWord && !word ? ' ' : word;
    return (React.createElement(React.Fragment, null,
        (participantOnWord === null || participantOnWord === void 0 ? void 0 : participantOnWord.avaId) && (React.createElement("span", { style: {
                position: 'absolute',
                fontSize: 12,
                marginTop: -14,
                borderLeft: 'solid',
                borderWidth: 2,
                borderColor: participantOnWord ? 'darkred' : undefined,
                paddingLeft: 3,
                backgroundColor: 'rgba(10,10,10,0.5)',
                pointerEvents: 'none',
            } }, participantOnWord === null || participantOnWord === void 0 ? void 0 : participantOnWord.userName)),
        React.createElement("span", { className: classes, id: participantOnWord === null || participantOnWord === void 0 ? void 0 : participantOnWord.userName, style: {
                backgroundColor: participantOnWord ? 'darkred' : undefined,
                color: (participantOnWord === null || participantOnWord === void 0 ? void 0 : participantOnWord.avaId) ? 'white' : undefined,
            } }, shownWord)));
};
const ScribeWord = ({ allowEdit, highlighted, scribe, transcriptId, unlocked, word, wordIndex, asrFinal, id, }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const participantOnWord = useAppSelector((state) => selectParticipantOnWord(state, transcriptId, wordIndex));
    const captionColor = useAppSelector((state) => selectCurrentCaptionsColor(state, id, theme, wordIndex));
    return (React.createElement("span", null,
        React.createElement("span", { style: {
                whiteSpace: 'pre-wrap',
                backgroundColor: scribe && (!asrFinal || unlocked) ? theme.palette.ava.asrGrey : 'transparent',
                fontWeight: theme.palette.bold === 'true' || highlighted ? 600 : undefined,
                fontStyle: !highlighted && !asrFinal && scribe ? 'italic' : 'normal',
                color: captionColor,
            }, onClick: () => {
                if (allowEdit) {
                    dispatch(scribeSelectTranscriptWord({
                        index: wordIndex,
                        indexTranscript: transcriptId,
                        skipCommit: true,
                        skipJumpNextBloc: true,
                    }));
                }
            }, role: "button", "data-qa": "captions" }, parseWord(word, '', allowEdit ? participantOnWord : undefined)),
        ' '));
};
export default ScribeWord;
