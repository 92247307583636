import { setTtsError, setUserTyping } from '../store/slices/textToSpeech';
import { sendWsMessage } from './ws-v1';
export const sortVoiceByGender = (ttsVoices, gender) => {
    const femaleVoices = new Map();
    const neutralVoices = new Map();
    const maleVoices = new Map();
    ttsVoices.forEach((voice, key) => {
        if (voice.gender === 'neutral') {
            neutralVoices.set(key, voice);
        }
        else if (voice.gender === 'female') {
            femaleVoices.set(key, voice);
        }
        else {
            maleVoices.set(key, voice);
        }
    });
    let sortedVoices = new Map();
    if (gender === 'neutral') {
        sortedVoices = new Map([...neutralVoices, ...femaleVoices, ...maleVoices]);
    }
    else if (gender === 'female') {
        sortedVoices = new Map([...femaleVoices, ...neutralVoices, ...maleVoices]);
    }
    else {
        sortedVoices = new Map([...maleVoices, ...neutralVoices, ...femaleVoices]);
    }
    return sortedVoices;
};
export const isAudioPlaying = (audioElement) => {
    return !audioElement.paused && !audioElement.ended && audioElement.currentTime > 0;
};
export const stopAudio = (audioElement) => {
    audioElement.pause();
    audioElement.currentTime = 0;
};
export const getImageNameForVoice = (voice) => {
    if (!voice)
        return;
    const voiceImageMapping = [
        ['nova', 'alloy', 'echo'],
        ['shimmer', 'fable', 'onyx'],
    ];
    const index = voiceImageMapping.findIndex((row) => row.includes(voice.voiceProviderId));
    return `${voice.gender}${index + 1}`;
};
export class TextToSpeechManager {
    constructor(ws, dispatch) {
        this.ws = ws;
        this.dispatch = dispatch;
        this.ws.addEventListener('message', (event) => {
            const message = JSON.parse(event.data);
            this.handleMessage(message);
        });
        this.handleMessage = this.handleMessage.bind(this);
    }
    handleMessage(response) {
        var _a;
        if (response.type === 'room-status-update') {
            if (response.reason && response.reason === 'someone-is-typing') {
                const user = (_a = response.participants) === null || _a === void 0 ? void 0 : _a.find((participant) => participant.isTyping === true);
                this.dispatch(setUserTyping(user));
            }
            else {
                if (response.participants) {
                    const noLongerTyping = !response.participants.some((participant) => participant.isTyping === true);
                    if (noLongerTyping) {
                        this.dispatch(setUserTyping(undefined));
                    }
                }
            }
        }
        else if (response.type === 'response-text-to-speech') {
            if (response.error) {
                setTtsError(response.error);
            }
        }
    }
    handleTextToSpeech(message) {
        sendWsMessage(this.ws, Object.assign({ type: 'request-text-to-speech' }, message));
    }
    handleSendIAmTyping(userTyping) {
        sendWsMessage(this.ws, { type: 'signal-i-am-typing', fromUser: userTyping });
    }
}
