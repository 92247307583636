import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scribeCreateTranscript, scribeDeselectTranscriptWord } from '../../../actions';
import SendIconCC from '../../../components/Icons/SendIconCC';
import useTextToSpeechManager from '../../../hooks/useTextToSpeechManager';
import TtsButton from '../../../modules/textToSpeech/TtsButton';
import { selectCurrentSelectedVoice, selectIsUsingTts } from '../../../selectors/textToSpeech';
import { selectElectronCaptionMode, selectFontSize } from '../../../selectors/ui';
import { selectAvaId, selectUserName } from '../../../selectors/userProfile';
import { stopRecording } from '../../../store/slices/audioV2';
import { setErrorAndReset } from '../../../store/slices/textToSpeech';
import { setKaraokeState } from '../../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { KARAOKE_PRE_DELAY_MAPPING } from '../../../utils/karaoke';
import useStyles from './KeyboardInput.styles';
const KeyboardInput = ({ onCloseKeyboard }) => {
    const [message, setMessage] = useState('');
    const [buttonState, setButtonState] = useState('disabled');
    const [isAlreadyTyping, setIsAlreadyTyping] = useState(false);
    const fontSize = useAppSelector(selectFontSize);
    const classes = useStyles({ fontSize, buttonState });
    const { t } = useTranslation();
    const avaId = useAppSelector(selectAvaId);
    const currentSelectedVoice = useAppSelector(selectCurrentSelectedVoice);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const userName = useAppSelector(selectUserName);
    const textToSpeechManager = useTextToSpeechManager();
    const dispatch = useAppDispatch();
    const isUsingTts = useAppSelector(selectIsUsingTts);
    const volumeRef = useRef();
    const [animating, setAnimating] = useState(false);
    const [showErrorOnce, setShowErrorOnce] = useState(false);
    const sendMessage = (karaoke) => {
        dispatch(scribeDeselectTranscriptWord());
        const newTranscript = dispatch(scribeCreateTranscript(undefined, message, avaId, 1, false, undefined));
        if (karaoke && newTranscript && currentSelectedVoice) {
            const startDelay = KARAOKE_PRE_DELAY_MAPPING[currentSelectedVoice.voiceProviderId];
            setTimeout(() => {
                dispatch(setKaraokeState({ wordIndex: 0, transcriptId: newTranscript.id }));
            }, startDelay);
            dispatch(stopRecording());
        }
        dispatch(scribeDeselectTranscriptWord());
        setMessage('');
        setIsAlreadyTyping(false);
    };
    const onSynthesizeSpeech = (event, karaoke) => {
        event.preventDefault();
        event.stopPropagation();
        textToSpeechManager === null || textToSpeechManager === void 0 ? void 0 : textToSpeechManager.handleTextToSpeech({
            text: message,
            voiceGender: currentSelectedVoice === null || currentSelectedVoice === void 0 ? void 0 : currentSelectedVoice.gender,
            voiceId: currentSelectedVoice === null || currentSelectedVoice === void 0 ? void 0 : currentSelectedVoice.id,
        });
        sendMessage(karaoke);
    };
    const handleSend = useCallback((e) => {
        setAnimating(true);
        setButtonState('active');
        if (!isUsingTts) {
            sendMessage(false);
        }
        else {
            onSynthesizeSpeech(e, true);
        }
        setTimeout(() => {
            setAnimating(false);
        }, 300);
    }, [isUsingTts, message]);
    useEffect(() => {
        if (electronCaptionMode && window.electronIPC) {
            window.electronIPC.invokeGetVolume().then((volume) => {
                volumeRef.current = volume;
            });
        }
    }, []);
    useEffect(() => {
        setButtonState(!message.length && !animating ? 'disabled' : animating ? 'active' : 'inactive');
    }, [message, animating]);
    useEffect(() => {
        if (volumeRef.current !== undefined &&
            volumeRef.current <= 50 &&
            isUsingTts &&
            message.length > 0 &&
            !showErrorOnce) {
            dispatch(setErrorAndReset('unableToHear'));
            setShowErrorOnce(true);
        }
    }, [isUsingTts, message]);
    useEffect(() => {
        if (!userName || !avaId)
            return;
        if (message.length > 0 && !isAlreadyTyping) {
            setIsAlreadyTyping(true);
            textToSpeechManager === null || textToSpeechManager === void 0 ? void 0 : textToSpeechManager.handleSendIAmTyping({ userName, avaId });
        }
        if (message.length === 0 && isAlreadyTyping) {
            setIsAlreadyTyping(false);
        }
    }, [message, isAlreadyTyping]);
    return (React.createElement("div", { className: classes.root },
        React.createElement("section", { className: classes.inputContainer },
            React.createElement("button", { className: classes.closeKeyboardInputButton, onClick: onCloseKeyboard },
                React.createElement(KeyboardArrowUpIcon, { className: classes.keyboardArrowUpIcon })),
            React.createElement("div", { className: classes.messageContainer },
                React.createElement("textarea", { className: classes.input, rows: "1", placeholder: t('conversation.typeTranscriptHere'), value: message, onChange: (event) => {
                        setMessage(event.target.value);
                    }, onKeyDown: (event) => {
                        if (['Enter'].includes(event.key)) {
                            // User is pressing shift + enter, allow the default action of
                            // adding another line and don't send a message.
                            if (!event.shiftKey) {
                                event.preventDefault();
                                handleSend(event);
                            }
                        }
                    }, spellCheck: false, autoFocus: true })),
            React.createElement("div", { className: classes.sendContainer },
                React.createElement(TtsButton, null),
                React.createElement(Tooltip, { classes: { tooltip: classes.tooltip, tooltipPlacementTop: classes.sendTooltipPlacementTop }, title: isUsingTts ? t('ccMode.keyboard.sendAsVoiceMessage') : t('ccMode.keyboard.tooltip.send'), placement: "top", disableInteractive: true },
                    React.createElement(IconButton, { disabled: buttonState === 'disabled', className: classes.sendButton, onClick: handleSend, size: "large" },
                        React.createElement(SendIconCC, { buttonState: buttonState })))))));
};
export default KeyboardInput;
