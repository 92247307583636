var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sleep } from '../../utils';
import { getSearchValue } from '../../utils/http';
import { checkEndsWithPunctuation, KARAOKE_DELAY_MAPPING, KARAOKE_RESET_DELAY } from '../../utils/karaoke';
import * as segment from '../../utils/segment';
import { startRecording } from './audioV2';
export var ScribeCorrectionMode;
(function (ScribeCorrectionMode) {
    ScribeCorrectionMode["Unset"] = "";
    ScribeCorrectionMode["Fast"] = "fast";
    ScribeCorrectionMode["Accurate"] = "accurate";
})(ScribeCorrectionMode || (ScribeCorrectionMode = {}));
export const ScribeCorrectionModeDefault = ScribeCorrectionMode.Fast;
const INITIAL_STATE = {
    loading: false,
    electronAppLoaded: false,
    sidebar: false,
    intercomOpen: false,
    isInviteCardOpen: false,
    readonly: getSearchValue(window, 'readonly', 'false') !== 'false',
    fontSize: 20,
    correctionMode: window.localStorage.getItem('scribe_correction_mode') || ScribeCorrectionMode.Unset,
    wordBeingEdited: false,
    fullScreen: false,
    metaDown: 0,
    displayShortcuts: false,
    insertSpeakerNameMode: false,
    electronCaptionMode: false,
    keyboardInputShown: false,
    isConnectToMeetingsOpen: false,
    karaokeState: {
        wordIndex: 0,
        transcriptId: '',
    },
    shouldShowSoloDiaSuccess: false,
    shouldHighlightConnectToMeetings: false,
};
const uiState = createSlice({
    name: 'uiState',
    initialState: INITIAL_STATE,
    reducers: {
        setMeta: (state, { payload }) => {
            state.metaDown = payload;
            state.displayShortcuts = state.insertSpeakerNameMode;
        },
        scribeSetFontSize: (state, { payload }) => {
            state.fontSize = payload;
        },
        setDisplayShortcuts: (state, { payload }) => {
            state.displayShortcuts = payload;
        },
        setScribeCorrectionMode: (state, { payload }) => {
            window.localStorage.setItem('scribe_correction_mode', payload);
            segment.track('Web - Correction Mode - Mode Changed', {
                mode: payload,
            });
            state.correctionMode = payload;
        },
        setIsWordBeingEdited: (state, { payload }) => {
            state.wordBeingEdited = payload;
        },
        setFullScreen: (state, { payload }) => {
            state.fullScreen = payload;
        },
        internalSetElectronCaptionMode: (state, { payload }) => {
            state.electronCaptionMode = payload;
        },
        setInsertSpeakerNameMode: (state, { payload }) => {
            state.insertSpeakerNameMode = payload;
            state.displayShortcuts = payload;
        },
        setElectronAppLoaded: (state, { payload }) => {
            state.electronAppLoaded = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        setIsInviteCardOpen: (state, { payload }) => {
            state.isInviteCardOpen = payload;
        },
        toggleSideBar: (state, { payload }) => {
            if (state.sidebar === payload)
                return state;
            state.sidebar = !state.sidebar;
        },
        cleanUpUi: () => {
            return INITIAL_STATE;
        },
        setIntercomOpen: (state, { payload }) => {
            state.intercomOpen = payload;
        },
        setKeyboardInputShown: (state, { payload }) => {
            state.keyboardInputShown = payload;
        },
        setConnectToMeetingsOpen: (state, { payload }) => {
            state.isConnectToMeetingsOpen = payload;
        },
        setKaraokeState: (state, { payload }) => {
            state.karaokeState = payload;
        },
        resetKaraokeState: (state) => {
            state.karaokeState = { wordIndex: 0, transcriptId: '' };
        },
        setShouldHighlightConnectToMeetings: (state, { payload }) => {
            state.shouldHighlightConnectToMeetings = payload;
        },
        setShouldShowSoloDiaSuccess: (state, { payload }) => {
            state.shouldShowSoloDiaSuccess = payload;
        },
    },
});
export const uiStateReducer = uiState.reducer;
export const { setMeta, setDisplayShortcuts, scribeSetFontSize, setScribeCorrectionMode, setFullScreen, setInsertSpeakerNameMode, setElectronAppLoaded, setLoading, setIsInviteCardOpen, toggleSideBar, cleanUpUi, setIntercomOpen, setIsWordBeingEdited, setConnectToMeetingsOpen, setKaraokeState, resetKaraokeState, setShouldHighlightConnectToMeetings, setShouldShowSoloDiaSuccess, } = uiState.actions;
const { setKeyboardInputShown, internalSetElectronCaptionMode } = uiState.actions;
export const updateKeyboardInputShown = createAsyncThunk('ui/updateKeyboardInputShown', (keyboardInputShown, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;
    // TODO: if muted -> keyboardInputShown false
    // TODO: On Room status update:
    dispatch(setKeyboardInputShown(keyboardInputShown));
}));
export const setElectronCaptionMode = createAsyncThunk('ui/setElectronCaptionMode', (electronCaptionMode, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const dispatch = thunkAPI.dispatch;
    (_a = window.electronIPC) === null || _a === void 0 ? void 0 : _a.sendSetInCaptionMode(electronCaptionMode);
    dispatch(internalSetElectronCaptionMode(electronCaptionMode));
}));
if (window.electronIPC) {
    window.electronIPC.onSetElectronCaptionMode((on) => {
        if (on) {
            window.navigate('/cc/conversation');
        }
        else {
            window.navigate('/web/transcript');
        }
    });
}
export const performKaraoke = createAsyncThunk('uiState/karaoke', ({ voice, language, transcriptId, wordCount, content, }, { dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    for (let currentWordIndex = 0; currentWordIndex < wordCount; currentWordIndex++) {
        const currentWord = content[currentWordIndex];
        const { isPunctuation, punctuationDelay } = checkEndsWithPunctuation(currentWord, voice, language);
        const karaokeDelay = KARAOKE_DELAY_MAPPING[voice];
        yield sleep(karaokeDelay);
        dispatch(setKaraokeState({ transcriptId, wordIndex: currentWordIndex }));
        if (isPunctuation) {
            yield sleep(punctuationDelay);
        }
    }
    yield sleep(KARAOKE_RESET_DELAY);
    dispatch(resetKaraokeState());
    dispatch(startRecording());
}));
